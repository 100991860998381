import * as icons from '@images'
import PropTypes from 'prop-types'

/**
 * @typedef {Object} IconParams
 * @property {'AlmaLinux'
 * |'ArrowSign'
 * |'Attention'
 * |'Bell'
 * |'Box'
 * |'Calendar'
 * |'CalendarArrow'
 * |'CalendarArrowDouble'
 * |'CentOS'
 * |'ChangePassword'
 * |'ChangeTariff'
 * |'Chats'
 * |'Check'
 * |'CheckCircle'
 * |'CheckEdit'
 * |'CheckFat'
 * |'CheckRound'
 * |'Check_square'
 * |'Client'
 * |'Clip'
 * |'Clock'
 * |'Close_square'
 * |'Console'
 * |'Copy'
 * |'Cross'
 * |'CsvDoc'
 * |'Cube'
 * |'Debian'
 * |'DefaultOs'
 * |'Delete'
 * |'DisLike'
 * |'DomainsListName'
 * |'Download'
 * |'DownloadImage'
 * |'DownloadWithFolder'
 * |'Edit'
 * |'EditPencil'
 * |'Envelope'
 * |'Error_404'
 * |'ErrorPay'
 * |'Euro'
 * |'Exchange'
 * |'ExitSign'
 * |'Eye'
 * |'EyeClosed'
 * |'Facebook'
 * |'FacebookSmall'
 * |'FilledEnvelope'
 * |'Filter'
 * |'Finance'
 * |'Flag'
 * |'Germany'
 * |'Gift'
 * |'GiftDt'
 * |'Google'
 * |'Headphone'
 * |'Help'
 * |'HintHelp'
 * |'Home'
 * |'IP'
 * |'InProgress'
 * |'Infinity'
 * |'Info'
 * |'Instruction'
 * |'Instruments'
 * |'Iso'
 * |'Key'
 * |'Launch'
 * |'Like'
 * |'Lock'
 * |'Logo'
 * |'Money'
 * |'Moon'
 * |'MoreDots'
 * |'On_Off'
 * |'Padlock'
 * |'PassChange'
 * |'Pay'
 * |'PaymentProcessing'
 * |'Pdf'
 * |'Person'
 * |'PhoneVerificationIcon'
 * |'Pin'
 * |'Plus'
 * |'Print'
 * |'Profile'
 * |'Protected'
 * |'Reboot'
 * |'Rebuild'
 * |'Reference'
 * |'Refund'
 * |'Reload'
 * |'Remove'
 * |'Rename'
 * |'Rescue'
 * |'Resize'
 * |'Restore'
 * |'Rocky'
 * |'SaleFiftyFive'
 * |'Search'
 * |'SendArchive'
 * |'Services'
 * |'Settings'
 * |'Shelve'
 * |'Shevron'
 * |'Shutdown'
 * |'Singapore'
 * |'SiteCareIcon'
 * |'Smile'
 * |'Social'
 * |'Sort_a_z'
 * |'Sort_z_a'
 * |'Ssh_keys'
 * |'Statistic'
 * |'SuccessPay'
 * |'Sun'
 * |'Support'
 * |'Timer'
 * |'Transfer'
 * |'Trash'
 * |'Ubuntu'
 * |'Update'
 * |'Usa'
 * |'Vk'
 * |'VkSmall'
 * |'VpnIcon'
 * |'Wallet'
 * |'WalletBalance'
 * |'Whois'
 * |'Windows'
 * |'Wrench'
 * |'Warning_triangle'
 * |'Maintenance'} name
 * @param {IconParams}
 */
export default function Icon({ name, ...props }) {
  const { [name]: Icon } = icons

  return <>{Icon && <Icon {...props} />}</>
}

Icon.propTypes = { name: PropTypes.oneOf(Object.keys(icons)) }
